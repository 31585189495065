import React from "react";

const NutriIntro = () => {
  return (
    <section id="nutriIntro" class="px-4 py-4">
        <div class="container mx-auto max-w-6xl md:flex items-start relative">
            <div class="absolute -top-5 right-0 -z-10 md:hidden">
                <img src="../img/intro-02.png" alt="hoja" class="w-[62px] h-auto" />
            </div>
            <div class="md:hidden w-11/12 mx-auto">
                <img src="../img/nutri-intro-01.png" alt="Plato rico, sano y casero" class="mx-auto" />
            </div>
            <div class="md:flex-1 md:pl-10">
                <div class="hidden md:block py-2">
                    <img src="../img/intro-03.png" alt="hoja" class="absolute w-[160px] -top-4 left-60" />
                    <svg class="ml-[130px] w-full h-auto" viewBox="0 0 635 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M600 116H475.07C472.512 116 470.109 114.778 468.604 112.711L447.896 84.2891C446.391 82.2224 443.988 81 441.43 81L296 81" stroke="url(#paint0_linear_525_212)"/>
                        <circle cx="293" cy="81" r="3.5" stroke="#FB7447"/>
                            <path d="M635 77H510.07C507.512 77 505.109 75.7776 503.604 73.7109L482.896 45.2891C481.391 43.2224 478.988 42 476.43 42H37.3883C34.9334 42 32.6144 40.8729 31.0977 38.9426L6 7" stroke="url(#paint1_linear_525_212)"/>
                            <defs>
                                <linearGradient id="paint0_linear_525_212" x1="296" y1="98.5" x2="600" y2="98.5" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FB7447"/>
                                    <stop offset="1" stop-color="#FB7447" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                    </svg>
                </div>
                <p class="text-mc-base text-lg mb-1 mt-4 md:text-2xl md:mt-0 md:mb-4">
                    Preparados, listos, ya!
                </p>
                <h1 class="font-extrabold text-mc-base text-2xl leading-tight mb-5 md:text-4xl md:pr-0">
                    Un equipo profesional que acompaña las distintas necesidades de las personas
                </h1>
                <p class="text-lg mb-7 leading-tight">
                    Aquí encontrarás el listado de nutricionistas que atienden las consultas y confeccionan los planes a medida. Platos que elaboran nuestros cocineros y llegan directo a tu hogar o espacio de trabajo.
                </p>
            </div>
            <div class="hidden md:block md:flex-1">
                <img src="../img/nutri-intro-desktop.png" alt="Plato rico, sano y casero" class="w-full max-w-[480px] pl-4" />
            </div>
        </div>
        <div class="hidden lg:block container mx-auto max-w-6xl -mt-10">
            <div class="w-[311px] h-[39px] -ml-12">
                <img src="../img/line-01.svg" alt="line" />
            </div>
            <img src="../img/intro-02.png" alt="hoja" class="w-[100px] h-auto rotate-180" />
        </div>
    </section>
  );
};

export default NutriIntro;
