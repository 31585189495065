import React from "react";

const DescargarApp = ({refProp}) => {
  return (
    <section ref={refProp} id="descargaApp" className="px-4 py-4 mb-20">
        <div className="container mx-auto max-w-6xl relative">
            <div className="mx-auto bg-mc-accent rounded-2xl p-8 my-10 md:flex md:min-h-64 relative">
                <img src="/img/descarga-app-hoja-01.png" alt="hoja" className="hidden lg:block absolute -top-16 right-[40%]" />
                <AppDownload />
                <img src="/img/descarga-app-hoja-02.png" alt="hoja" className="hidden lg:block absolute left-0 -bottom-[80px]" />
                <div className="-mb-16 md:hidden">
                    <img src="/img/descarga-app-img.png" alt="" />
                </div>
                <div class="hidden md:block absolute -bottom-24 right-8 max-w-md">
                    <img src="/img/descarga-app-img.png" alt="" />
                </div>
            </div>
        </div>
    </section>
  );
};

const AppDownload = () => {
    return (
      <div className="flex flex-col gap-y-4 flex-none md:w-1/2">
        <h3 className="text-white text-4xl font-semibold lg:text-[46px]">Descarga nuestra app</h3>
        <p className="text-white text-lg font-normal leading-tight">
          Desde la App podés cancelar tus platos y cambiar tu dirección de entrega
        </p>
        <div className="lg:flex gap-5">
          <div className="mb-4">
            <a href="https://play.google.com/store/apps/details?id=com.mejorcasero.mejorcasero" className="hover:drop-shadow-xl">
              <img src="/img/btn-google-play.svg" alt="icon google play" className="w-full h-auto" />
            </a>
          </div>
          <div className="mb-4">
            <a href="https://apps.apple.com/uy/app/mejor-casero/id1525306383" className="hover:drop-shadow-xl">
              <img src="/img/btn-app-store.svg" alt="icon app store" className="w-full h-auto" />
            </a>
          </div>
        </div>
      </div>
    );
  };

export default DescargarApp;


