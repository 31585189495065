import React from 'react';

const WhyChooseUs = () => {
  return (
    <section id="porQueElegirnos" className="px-4 py-4">
      <div className="container mx-auto max-w-6xl my-10">
        <div className="md:flex items-start">
          <div className="flex-1">
            <img src="/img/porque-elegirnos-01.png" alt="chef cocinando" className="w-10/12 mx-auto md:w-full" />
          </div>
          <div className="flex-1 md:-ml-10">
            <div className="hidden md:block mb-5">
              <img src="/img/porque-elegirnos-02.png" alt="hoja" className="-ml-44" />
            </div>
            <h2 className="text-3xl font-semibold mb-9 md:text-4xl">¿Por qué elegirnos?</h2>
            <div>
              <ul className="list-image-[url(../src/img/icon-list.svg)] list-inside *:mb-1">
                <li>Disfruta de <strong>comida fresca y saludable</strong> todos los días.</li>
                <li>Un <strong>chef exclusivo</strong> se encargará de preparar tus platos.</li>
                <li>Gestiona tu plan a través de <strong>nuestra aplicación</strong> dedicada.</li>
                <li>Ahorra <strong>tiempo y dinero</strong>.</li>
                <li><strong>Adaptabilidad</strong> a tu estilo de vida.</li>
                <li><strong>Envíos diarios gratis</strong> en todo Montevideo.</li>
                <li>Recibe tus comidas en <strong>viandas reutilizables.</strong></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
