import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import './output.css';
// import "./input.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Nutricionistas from "./pages/nutricionistas";
import Landing from "./pages/landing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/nutricionistas",
    element: <Nutricionistas />
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
    </RouterProvider>
  </React.StrictMode>
);

// Counter
function animateCount(element, start, end, duration) {
  var startTime = null;
  function updateCounter(timestamp) {
      if (!startTime) startTime = timestamp;
      var progress = timestamp - startTime;
      var percentage = Math.min(progress / duration, 1);
      var value = start + percentage * (end - start);
      element.innerHTML = value.toFixed(0); // decimales
      if (percentage < 1) {
          requestAnimationFrame(updateCounter);
      }
  }
  requestAnimationFrame(updateCounter);
}
// Intersection Observer
function createObserver() {
  const elements = document.querySelectorAll('.counter');
  const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
      if (entry.isIntersecting) {
      const endValue = parseInt(entry.target.textContent, 10);
      animateCount(entry.target, 0, endValue, 2000);
      // Dejar de observar después de la animación
      // observer.unobserve(entry.target);
      }
  });
  }, { threshold: 0.1 }); // El umbral de visibilidad
  elements.forEach((element) => {
  observer.observe(element);
  });
}
// Llamar a la función createObserver al cargar la página
window.addEventListener('DOMContentLoaded', createObserver);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
