import React from "react";
import { Link } from "react-router-dom";

const HomeIntro = ({howItWorksScroll}) => {

  return (
    <section id="homeIntro" className="px-4 py-4">
      <div className="container mx-auto max-w-6xl md:flex items-start relative">
        <div className="absolute top-0 -left-4 -z-10 md:hidden">
          <svg width="204" height="40" viewBox="0 0 204 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M203.161 19.9755H136.729C134.175 19.9755 131.775 18.7563 130.269 16.694L121.127 4.1773C119.621 2.115 117.22 0.895752 114.667 0.895752H0"
              stroke="url(#paint0_linear_507_204)"
            />
            <path
              d="M174.704 38.7042H115.586C113.032 38.7042 110.632 37.4849 109.126 35.4226L101.43 24.8858C99.9239 22.8235 97.5236 21.6042 94.9698 21.6042L25.812 21.6042"
              stroke="url(#paint1_linear_507_204)"
            />
            <path
              d="M25.8017 21.6042C25.8017 22.4062 25.1496 23.0585 24.3426 23.0585C23.5356 23.0585 22.8835 22.4062 22.8835 21.6042C22.8835 20.8022 23.5356 20.1499 24.3426 20.1499C25.1496 20.1499 25.8017 20.8022 25.8017 21.6042Z"
              stroke="#FB7447"
            />
            <defs>
              <linearGradient
                id="paint0_linear_507_204"
                x1="-103.414"
                y1="10.4356"
                x2="203.161"
                y2="10.4356"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FB7447" />
                <stop offset="1" stop-color="#FB7447" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint1_linear_507_204" x1="25.812" y1="30.1542" x2="174.704" y2="30.1542" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FB7447" />
                <stop offset="1" stop-color="#FB7447" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="absolute -top-4 right-0 -z-10 md:top-[175px] md:-right-14">
          <img src="/img/intro-02.png" alt="hoja" className="w-[62px] md:w-[98px] h-auto" />
        </div>

        <div className="md:hidden w-11/12 mx-auto">
          <img src="/img/intro-01.png" alt="Plato rico, sano y casero" className="mx-auto" />
        </div>

        <div className="md:flex-1 md:pl-10">
          <div className="hidden md:block py-8">
            <img src="/img/intro-03.png" alt="hoja" className="absolute w-[160px] top-0 left-40" />
            <svg class="ml-10 w-full h-auto" viewBox="0 0 635 117" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M600 116H475.07C472.512 116 470.109 114.778 468.604 112.711L447.896 84.2891C446.391 82.2224 443.988 81 441.43 81L296 81"
                stroke="url(#paint0_linear_525_212)"
              />
              <circle cx="293" cy="81" r="3.5" stroke="#FB7447" />
              <path
                d="M635 77H510.07C507.512 77 505.109 75.7776 503.604 73.7109L482.896 45.2891C481.391 43.2224 478.988 42 476.43 42H37.3883C34.9334 42 32.6144 40.8729 31.0977 38.9426L6 7"
                stroke="url(#paint1_linear_525_212)"
              />
              <circle cx="4" cy="4" r="3.5" stroke="#FB7447" />
              <defs>
                <linearGradient id="paint0_linear_525_212" x1="296" y1="98.5" x2="600" y2="98.5" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FB7447" />
                  <stop offset="1" stop-color="#FB7447" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_525_212" x1="74" y1="59.5" x2="635" y2="59.5" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FB7447" />
                  <stop offset="1" stop-color="#FB7447" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <h1 className="font-extrabold text-mc-base text-[40px] leading-tight mb-1 md:text-6xl md:pr-5">Viandas únicas y saludables</h1>
          <p className="text-lg mb-7 md:text-2xl">con el toque propio de nuestros chefs</p>
          <div className="flex flex-col gap-4 md:flex-row">
            {/*<Link to="/plan-ia" className="mc-btn mc-btn-primary">
              Generar plan con IA
            </Link>*/}
            <Link onClick={() => howItWorksScroll()} className="mc-btn mc-btn-primary-outline">
              Cómo Funciona
            </Link>
          </div>
        </div>

        <div className="hidden md:block md:flex-1">
          <img src="/img/intro-01-desktop.png" alt="Plato rico, sano y casero" className="-ml-14" />
          <div className="hidden md:block absolute bottom-36 rotate-180 ml-16">
            <img src="/img/intro-02.png" alt="hoja" className="w-[98px] h-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
