import React from "react";
import { Link } from "react-router-dom";

const Header = ({downloadScroll}) => {
  return (
    <header id="headerContent" className="px-4 py-9">
      <div className="container mx-auto max-w-6xl">
        <nav className="relative flex justify-between">
          {/* Logo */}
          <div id="logo" className="flex items-center md:gap-x-12 z-50 top-[39px]">
            <Link to="/" aria-label="Home">
              <img src="/img/navbar-logo.svg" alt="mejor casero" className="w-[157px] h-auto" />
            </Link>
          </div>
          {/* Menu */}
          <div id="menu" className="flex items-center gap-x-5 md:gap-x-8">
            {/* Menu desktop */}
            <div className="hidden lg:flex md:gap-x-6 font-navbar">
              {/*<Link className="inline-block px-2 py-1 text-lg text-black hover:text-mc-primary" to="/precios">
                Precios
              </Link>*/}
              <Link className="inline-block px-2 py-1 text-lg text-black hover:text-mc-primary" to="/nutricionistas">
                Nutricionistas
              </Link>
              {/*<Link className="inline-block px-2 py-1 text-lg text-black hover:text-mc-primary" to="/empresas">
                Plan Empresa
              </Link>*/}
              {/*<Link className="mc-btn mc-btn-primary" to="/plan-ia">
                Generar plan con IA
              </Link>*/}
              <Link onClick={() => downloadScroll()}className="mc-btn mc-btn-secondary-outline">
                <span>Descargar App</span>
              </Link>
            </div>
            {/* Button for mobile menu */}
            <div className="lg:hidden z-50 right-4 top-9 iconMenuMobile">
              <button
                id="btnMenuMobileOpen"
                type="button"
                aria-label="Open"
                aria-expanded="false"
                className="flex h-12 w-12 items-center justify-center"
                // onClick handler to toggle menu visibility might be needed here
              >
                {/* SVG icons */}
              </button>
              {/* <div class="lg:hidden z-50 right-4 top-9 iconMenuMobile">
              <button
                id="btnMenuMobileOpen"
                type="button"
                aria-label="Open"
                aria-expanded="false"
                class="flex h-12 w-12 items-center justify-center"
              >
                <svg class="iconOpen" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                  <circle cx="22.5" cy="22.5" r="22.5" fill="black" />
                  <path
                    d="M33.1014 13H12.63C11.7298 13 11 13.7298 11 14.63C11 15.5302 11.7298 16.26 12.63 16.26H33.1014C34.0016 16.26 34.7314 15.5302 34.7314 14.63C34.7314 13.7298 34.0016 13 33.1014 13Z"
                    fill="white"
                  />
                  <path
                    d="M37.37 21H8.63C7.72978 21 7 21.7298 7 22.63C7 23.5302 7.72978 24.26 8.63 24.26H37.37C38.2702 24.26 39 23.5302 39 22.63C39 21.7298 38.2702 21 37.37 21Z"
                    fill="white"
                  />
                  <path
                    d="M33.1014 29.52H12.63C11.7298 29.52 11 30.2498 11 31.15C11 32.0502 11.7298 32.78 12.63 32.78H33.1014C34.0016 32.78 34.7314 32.0502 34.7314 31.15C34.7314 30.2498 34.0016 29.52 33.1014 29.52Z"
                    fill="white"
                  />
                </svg>
                <svg class="iconClose hidden" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                  <circle cx="22.5" cy="22.5" r="22.5" fill="black" />
                  <path
                    d="M33.78 31.4748L13.4578 11.1526C12.8212 10.516 11.7891 10.516 11.1526 11.1526C10.516 11.7891 10.516 12.8212 11.1526 13.4578L31.4748 33.78C32.1114 34.4166 33.1435 34.4166 33.78 33.78C34.4166 33.1434 34.4166 32.1114 33.78 31.4748Z"
                    fill="white"
                  />
                  <path
                    d="M11.1526 31.4748L31.4749 11.1526C32.1114 10.516 33.1435 10.516 33.78 11.1526C34.4166 11.7891 34.4166 12.8212 33.78 13.4578L13.4578 33.78C12.8212 34.4166 11.7892 34.4166 11.1526 33.78C10.5161 33.1434 10.5161 32.1114 11.1526 31.4748Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div> */}
              <div class="lg:hidden z-50 right-4 top-9 iconMenuMobile">
                <button
                  id="btnMenuMobileOpen"
                  type="button"
                  aria-label="Open"
                  aria-expanded="false"
                  class="flex h-12 w-12 items-center justify-center"
                >
                  <svg class="iconOpen" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="black" />
                    <path
                      d="M33.1014 13H12.63C11.7298 13 11 13.7298 11 14.63C11 15.5302 11.7298 16.26 12.63 16.26H33.1014C34.0016 16.26 34.7314 15.5302 34.7314 14.63C34.7314 13.7298 34.0016 13 33.1014 13Z"
                      fill="white"
                    />
                    <path
                      d="M37.37 21H8.63C7.72978 21 7 21.7298 7 22.63C7 23.5302 7.72978 24.26 8.63 24.26H37.37C38.2702 24.26 39 23.5302 39 22.63C39 21.7298 38.2702 21 37.37 21Z"
                      fill="white"
                    />
                    <path
                      d="M33.1014 29.52H12.63C11.7298 29.52 11 30.2498 11 31.15C11 32.0502 11.7298 32.78 12.63 32.78H33.1014C34.0016 32.78 34.7314 32.0502 34.7314 31.15C34.7314 30.2498 34.0016 29.52 33.1014 29.52Z"
                      fill="white"
                    />
                  </svg>
                  <svg class="iconClose hidden" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="black" />
                    <path
                      d="M33.78 31.4748L13.4578 11.1526C12.8212 10.516 11.7891 10.516 11.1526 11.1526C10.516 11.7891 10.516 12.8212 11.1526 13.4578L31.4748 33.78C32.1114 34.4166 33.1435 34.4166 33.78 33.78C34.4166 33.1434 34.4166 32.1114 33.78 31.4748Z"
                      fill="white"
                    />
                    <path
                      d="M11.1526 31.4748L31.4749 11.1526C32.1114 10.516 33.1435 10.516 33.78 11.1526C34.4166 11.7891 34.4166 12.8212 33.78 13.4578L13.4578 33.78C12.8212 34.4166 11.7892 34.4166 11.1526 33.78C10.5161 33.1434 10.5161 32.1114 11.1526 31.4748Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
