import React, { useRef } from "react";
import Header from "../components/common/Header";
import MobileMenu from "../components/landing/MobileMenu";
import HomeIntro from "../components/landing/HomeIntro";
import HowItWorks from "../components/landing/HowItWorks";
import WhyChooseUs from "../components/landing/WhyChooseUs";
import OurDishes from "../components/landing/OurDishes";
import Plans from "../components/landing/Plans";
import InNumbers from "../components/landing/InNumbers";
import Footer from "../components/common/Footer";
import Whatsapp from "../components/landing/Whatsapp";
import DescargarApp from "../components/common/DescargarApp";
const Landing = () => {
  const howItWorksRef = useRef(null);
  const downloadRef = useRef(null);

  const howItWorksScroll = () => {
    howItWorksRef.current.scrollIntoView();
  }

  const downloadScroll = () => {
    downloadRef.current.scrollIntoView();
  }

  return (
    <div class="text-mc-base font-body bg-[#FFFEFA] bg-[url('./img/home-bg-blur.svg')] bg-no-repeat bg-cover bg-left-top">
      <Header downloadScroll={downloadScroll}></Header>
      <MobileMenu></MobileMenu>
      <HomeIntro howItWorksScroll={howItWorksScroll}></HomeIntro>
      <HowItWorks refProp={howItWorksRef}></HowItWorks>
      <WhyChooseUs></WhyChooseUs>
      <OurDishes></OurDishes>
      <Plans> </Plans>
      <DescargarApp refProp={downloadRef}></DescargarApp>
      <InNumbers></InNumbers>
      <Footer></Footer>
      <Whatsapp></Whatsapp>
    </div>
  );
}

export default Landing;
