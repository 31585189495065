import React from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = () => {
  return (
    <div
      id="menuMobile"
      className="hidden w-full max-w-full px-4 pb-9 pt-[120px] fixed top-0 z-40 h-[calc(100vh)]"
      style={{
        background: '#fffefa radial-gradient(50% 50% at 50% 50%, rgba(255, 75, 0, 0.08) 0%, rgba(255, 75, 0, 0) 100%)'
      }}
    >
      <div className="flex flex-col h-full justify-between gap-4">
        <nav>
          <ul>
            <li>
              <Link to="/" className="block font-semibold text-xl p-4 border-b border-mc-primary hover:text-mc-primary">Home</Link>
            </li>
            <li>
              <Link to="/plan-ia" className="block font-semibold text-xl p-4 border-b border-mc-primary hover:text-mc-primary">Generar Plan con IA</Link>
            </li>
            <li>
              <Link to="/precios" className="block font-semibold text-xl p-4 border-b border-mc-primary hover:text-mc-primary">Precios</Link>
            </li>
            <li>
              <Link to="/nutricionistas" className="block font-semibold text-xl p-4 border-b border-mc-primary hover:text-mc-primary">Nutricionistas</Link>
            </li>
            <li>
              <Link to="/empresas" className="block font-semibold text-xl p-4 hover:text-mc-primary">Plan Empresas</Link>
            </li>
          </ul>
        </nav>
        <div className="flex flex-col items-center gap-4">
          <div className="font-semibold text-lg">Descarga nuestra app</div>
          <div className="flex gap-4">
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.mejorcasero.mejorcasero" className="hover:drop-shadow-xl">
                <img src="/img/btn-google-play.svg" alt="GooglePlay" className="w-full max-w-32" />
              </a>
            </div>
            <div>
              <a href="https://apps.apple.com/uy/app/mejor-casero/id1525306383" className="hover:drop-shadow-xl">
                <img src="/img/btn-app-store.svg" alt="AppStore" className="w-full max-w-32" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
