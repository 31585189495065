import React from 'react';
import { Link } from 'react-router-dom';

const Plans = () => {
  return (
    <section id="diferentesPlanes" className="px-4 py-4">
      <div className="container mx-auto max-w-6xl relative">
        <div className="my-10 md:flex items-center">
          <div className="w-10/12 mx-auto pl-9 md:flex-1 md:w-2/3">
            <img src="/img/diferentes-planes-01.png" alt="plato ia" className="mx-auto" />
          </div>
          <div className="md:flex-auto md:w-1/3">
            <h2 className="text-3xl font-semibold md:text-5xl">Conocé los diferentes planes con los que trabajamos</h2>
          </div>
        </div>

        <div className="flex flex-col gap-y-6 text-center md:flex-row md:gap-x-6 md:mb-36">
          <PlanCard
            imgSrc="/img/diferentes-planes-ia.png"
            title="IA"
            description="¿Querés armar tu plan con inteligencia artificial?"
            link="/pages/plan-ia.html"
            linkText="Empeza con nosotros"
          />
          <PlanCard
            imgSrc="/img/diferentes-planes-nutricion.png"
            title="Nutricionista"
            description="¿Querés armar tu plan con una nutricionista?"
            link="/pages/nutricionistas.html"
            linkText="Agendá tu consulta"
          />
          <PlanCard
            imgSrc="/img/diferentes-planes-empresa.png"
            title="Empresas"
            description="¿Querés armar tu plan para empresas?"
            link="/pages/empresas.html"
            linkText="Ver Plan Empresas"
          />
        </div>
      </div>
    </section>
  );
};

const PlanCard = ({ imgSrc, title, description, link, linkText }) => {
  return (
    <div className="bg-mc-primary-light rounded-xl rounded-tl-[52px] border border-solid border-mc-primary/50 p-4 shadow-xl">
      <div className="mb-5">
        <img src={imgSrc} alt={title} className="w-full drop-shadow-[0_0_1px_rgba(254,111,78,1)]" />
      </div>
      <div>
        <h3 className="text-3xl font-semibold text-mc-primary mb-3">{title}</h3>
        <p className="text-xl font-medium mb-5">{description}</p>
        {/*<Link to={link} className="mc-btn mc-btn-primary w-full">{linkText}</Link>*/}
      </div>
    </div>
  );
};

export default Plans;
